import { set } from 'lodash'

import de from 'apexcharts/dist/locales/de.json'
import en from 'apexcharts/dist/locales/en.json'
import fr from 'apexcharts/dist/locales/fr.json'
import it from 'apexcharts/dist/locales/it.json'

import { measurementHelper } from '@/utils'

const locales = {
    de,
    en,
    fr,
    it,
}

const xAxisTranslation = {
    de: 'Zeit',
    en: 'Time',
    fr: 'Temps',
    it: 'Tempo',
}

const colorsDefault = [
    '#008ffb',
    '#00e396',
    '#feb019',
    '#ff4560',
    '#775dd0',
    '#546e7a',
    '#8d5b4c',
    '#4ecdc4',
    '#f9a3a4',
    '#f86624',
]

export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    locales: [locales[this.$i18n.locale] || de],
                    defaultLocale: locales[this.$i18n.locale]
                        ? this.$i18n.locale
                        : 'de',
                    toolbar: {
                        export: {
                            csv: {
                                headerCategory:
                                    xAxisTranslation[this.$i18n.locale],
                                dateFormatter(timestamp) {
                                    return timestamp
                                },
                            },
                        },
                    },
                    events: {
                        beforeResetZoom: this.handleZoomReset,
                        legendClick: this.handleLegendClick,
                        zoomed: this.handleZoom,
                    },
                },
                colors: colorsDefault,
                colorsDefault,
                xaxis: {
                    labels: {
                        datetimeUTC: false,
                    },
                },
            },
            collapsedSeriesIndices: [],
            lastZoom: null,
        }
    },
    watch: {
        lastZoom() {
            if (this.chartOptions.annotations?.points?.length) {
                this.setExtremaAnnotationPoints()
            }
        },
        series() {
            this.setExtremaAnnotationPoints()
            this.$nextTick(() => {
                if (this.$refs.chart) {
                    this.collapsedSeriesIndices.forEach(index => {
                        this.$refs.chart.hideSeries(this.series[index].name)
                    })
                }
            })
        },
    },
    methods: {
        getYMaxValue(measurement) {
            switch (measurement) {
                case 'signal':
                    return -30
                case 'humidity_absolute':
                    return 30
                case 'battery':
                case 'fill_level':
                case 'fuel_level':
                case 'humidity':
                case 'temperature':
                    return 100
                case 'moisture':
                    return 120
                case 'power':
                    return 3000
            }
        },
        getYMinValue(measurement) {
            switch (measurement) {
                case 'signal':
                    return -150
                case 'temperature':
                    return -25
                case 'moisture':
                    return -5
                case 'battery':
                case 'battery_voltage':
                case 'co2':
                case 'distance':
                case 'fill_level':
                case 'fuel_level':
                case 'humidity':
                case 'level':
                case 'mass':
                case 'power':
                case 'speed':
                case 'voc':
                case 'volume':
                    return 0
                case 'humidity_absolute':
                    return 5
            }
        },
        handleLegendClick(_, seriesIndex) {
            const clickedIndex = this.collapsedSeriesIndices.findIndex(
                index => index === seriesIndex
            )
            if (clickedIndex >= 0) {
                this.collapsedSeriesIndices.splice(clickedIndex, 1)
            } else {
                this.collapsedSeriesIndices.push(seriesIndex)
            }
            this.setExtremaAnnotationPoints()
        },
        handleSettingsChange(mode, ...args) {
            switch (mode) {
                case 'auto':
                    this.chartOptions.yaxis.min = undefined
                    this.chartOptions.yaxis.max = undefined
                    break
                case 'custom':
                    this.chartOptions.yaxis.min =
                        typeof args[0] === 'number' ? args[0] : undefined
                    this.chartOptions.yaxis.max =
                        typeof args[1] === 'number' ? args[1] : undefined
                    break
                case 'default':
                    this.chartOptions.yaxis.min = this.getYMinValue(
                        this.dataType
                    )
                    this.chartOptions.yaxis.max = this.getYMaxValue(
                        this.dataType
                    )
                    break
            }
            this.$refs.chart?.refresh()
        },
        handleZoom(_, { xaxis: { min, max } }) {
            this.lastZoom = [min, max]
        },
        handleZoomReset() {
            this.lastZoom = null
        },
        setExtremaAnnotationPoints() {
            if (this.chartOptions.chart.type !== 'line') {
                return
            }

            const hasMultipleAxis = this.chartOptionsComputed?.yaxis.length > 1
            const points = this.series?.reduce(
                (annotationPoints, { data, key, unit }, i) => {
                    if (this.collapsedSeriesIndices.includes(i)) {
                        return annotationPoints
                    }

                    return [
                        ...annotationPoints,
                        ...data
                            .reduce((extremas, item) => {
                                if (this.lastZoom?.length) {
                                    const time = new Date(item[0]).getTime()
                                    if (
                                        time < this.lastZoom[0] ||
                                        time > this.lastZoom[1]
                                    ) {
                                        return extremas
                                    }
                                }
                                const value = +item[1]
                                if (!extremas[0] || value < +extremas[0][1]) {
                                    extremas[0] = item
                                }
                                if (!extremas[1] || value > +extremas[1][1]) {
                                    extremas[1] = item
                                }
                                return extremas
                            }, [])
                            .filter(Boolean)
                            .map(([timestamp, value]) => ({
                                x: new Date(timestamp).getTime(),
                                y: value,
                                seriesIndex: i,
                                marker: {
                                    strokeColor: this.chartOptions.colors[i],
                                },
                                label: {
                                    text: `${value} ${unit ||
                                        measurementHelper.units[key] ||
                                        ''}`,
                                    offsetY: 30,
                                    borderColor: '#fff',
                                    style: {
                                        background: this.chartOptions.colors[i],
                                        color: '#fff',
                                        fontWeight: 700,
                                        padding: {
                                            top: 2,
                                            left: 4,
                                            bottom: 2,
                                            right: 4,
                                        },
                                    },
                                },
                                ...(hasMultipleAxis && {
                                    yAxisIndex: i,
                                }),
                            })),
                    ]
                },
                []
            )

            const newChartOptions = { ...this.chartOptions }
            set(newChartOptions, 'annotations.points', points)
            this.chartOptions = newChartOptions
        },
    },
}
