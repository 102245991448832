import { get, isNil, set } from 'lodash'
import moment from 'moment-timezone'

const serializers = {
    date: value => {
        const date = moment(value)
        return date.isValid()
            ? date.format(moment.HTML5_FMT.DATETIME_LOCAL)
            : null
    },
}

const deserializers = {
    array: value => [].concat(value),
    date: value => {
        const date = moment(value)
        return date.isValid() ? date.toDate() : null
    },
}

export default {
    data() {
        return {
            _unwatch: null,
        }
    },
    beforeDestroy() {
        this._unwatch?.()
    },
    methods: {
        keepAsQueryParams(restore, data) {
            this._unwatch?.()
            this._unwatch = this.$watch(
                () => Object.keys(data).map(key => get(this.$data, key)),
                values => {
                    const query = Object.values(data).reduce(
                        (acc, { key, type, serialize }, i) => {
                            let value = values[i]
                            if (serializers[type]) {
                                value = serializers[type](value)
                            }
                            if (serialize) {
                                value = serialize(value)
                            }
                            if (!isNil(value)) {
                                acc[key] = value
                            }
                            return acc
                        },
                        { ...this.$route.query }
                    )
                    this.$router.replace({ params: this.$route.params, query })
                }
            )

            if (restore) {
                const queryKeyMap = Object.entries(data).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [value.key]: key,
                    }),
                    {}
                )
                Object.entries(this.$route.query).forEach(
                    ([queryKey, value]) => {
                        const key = queryKeyMap[queryKey]
                        if (!key) {
                            return
                        }
                        const { type, deserialize } = data[key]
                        if (deserializers[type]) {
                            value = deserializers[type](value)
                        }
                        if (deserialize) {
                            value = deserialize(value)
                        }
                        if (!isNil(value)) {
                            set(this.$data, key, value)
                        }
                    }
                )
            }
        },
    },
}
